<template>
  <div
    class="d-flex flex-column flex-md-row align-items-center p-3 px-md-4 mb-3
    bg-white border-bottom shadow-sm"
  >
    <div class="d-flex flex-row align-items-center mr-md-auto">
      <div class="my-0 font-weight-normal head-image-container">
        <img src="@/assets/logo-e-blue.svg" class="head-image" alt="ETU">
        <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" fill="#ffffff" class="bi bi-code-slash rounded-circle" style="background-color: #05336E;" viewBox="0 0 16 16">
          <path stroke="null" d="m10.09603,2.62643a0.42293,0.42293 0 1 0
          -0.80864,-0.24868l-3.38342,10.9961a0.42293,0.42293 0 0 0
          0.80864,0.24868l3.38342,-10.9961zm-4.75708,2.11379a0.42293,0.42293 0 0
          1 0,0.59886l-2.6619,2.66106l2.6619,2.66106a0.42293,0.42293 0 0 1
          -0.59886,0.59886l-2.96049,-2.96049a0.42293,0.42293 0 0 1
          0,-0.59886l2.96049,-2.96049a0.42293,0.42293 0 0 1 0.59886,0zm5.32211,0a0.42293,0.42293 0
           0 0 0,0.59886l2.6619,2.66106l-2.6619,2.66106a0.42293,0.42293 0 0 0
           0.59886,0.59886l2.96049,-2.96049a0.42293,0.42293 0 0 0
           0,-0.59886l-2.96049,-2.96049a0.42293,0.42293 0 0 0 -0.59886,0z"/>
        </svg>
      </div>
      <div>
<!--        <div class="head-title d-none d-md-block h2 mb-0">-->
<!--          ETU IT-->
<!--        </div>-->
      </div>
    </div>
    <nav class="my-2 my-md-0">
<!--    <nav class="my-2 my-md-0 mr-md-3">-->
      <a class="p-2 text-dark" href="#services">Сервисы</a>
      <a class="p-2 text-dark" href="#digital">ЦНОП</a>
      <a class="p-2 text-dark" href="#hiring">Вакансии</a>
    </nav>
    <a class="btn btn-outline-primary" href="https://lk.etu.ru/dev">
      <i class="bi-terminal"></i> Developer portal
    </a>
  </div>
</template>

<script>
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class Header extends Vue {}
</script>

<style scoped>

</style>































































































import { Component, Vue } from 'vue-property-decorator';
import Header from '@/components/Header.vue';
import Wave from '@/components/Wave.vue';
import { services } from '../data/services.json';

@Component({
  components: {
    Wave,
    Header,
  },
})
export default class Home extends Vue {
  // eslint-disable-next-line class-methods-use-this
  get year(): number {
    return new Date().getFullYear();
  }

  // eslint-disable-next-line class-methods-use-this
  get services(): Array<Record<string, unknown>> {
    return services;
  }
}















import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class Wave extends Vue {
  @Prop({ default: 'blue' })
  readonly variant!: string

  @Prop({ default: 'bottom' })
  readonly position!: 'bottom' | 'top'

  get variantClass(): string {
    return `wave-path-${this.variant}`;
  }

  get pathD(): string {
    if (this.position === 'bottom') {
      return 'M0,160L120,176C240,192,480,224,720,208C960,192,1200,128,1320,96L1440,64L1440,320L1320,320C1200,320,960,320,720,320C480,320,240,320,120,320L0,320Z';
    }
    return 'M0,192L120,165.3C240,139,480,85,720,74.7C960,64,1200,96,1320,112L1440,128L1440,0L1320,0C1200,0,960,0,720,0C480,0,240,0,120,0L0,0Z';
  }
}
